import { FlexboxGrid, Col, Button, Stack } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Link from 'next/link'

import SafeTranslate from '../common/SafeTranslate'
import { imageLoader } from '../../utils/images'
import Img from '../Img'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import useUrls from '../../services/useUrls'
import useLoginModal from '../../services/useLoginModal'
import useIsOnMobile from '../../services/useIsOnMobile'
import { useAuth } from '../../services/useAuth'

import buttonStyles from '../../styles/CustomButtons.module.less'
import styles from '../../styles/LandingPage/LandingPage.module.less'

const HeroSection = () => {
  const { urlT, pushT } = useUrls()
  const { locale, defaultLocale } = useRouter()
  const { t } = useTranslation('landing')
  const { locale: lang } = getCountryAndLocaleStrings((locale || defaultLocale!))
  const { user } = useAuth()
  const {
    openRegisterModal,
  } = useLoginModal()

  const getButtonClassNames = ({ isTransparent }: { isTransparent?: boolean }): string => `
    ${buttonStyles['custom-button']}
    ${buttonStyles['button-primary']}
    ${isTransparent ? buttonStyles['button-transparent'] : styles.primary}
    ${buttonStyles['button-caret']}
    align-middle-horizontally
    text-center
    ${styles['cta-button']}
`

  const isOnMobile = useIsOnMobile()

  const heading = user
    ? (
      <SafeTranslate
        i18nKey="landing:Welcome back name"
        components={{
          span: <span className="highlight-text" />,
        }}
        values={{ name: user?.firstname || '' }}
      />
    )
    : (
      <SafeTranslate
        i18nKey="common:The most efficient way to source and order supplies for your operations"
        components={{
          span: <span className="highlight-text" />,
        }}
      />
    )

  return (
    <div className={styles['hero-section-container']}>
      <FlexboxGrid justify={isOnMobile ? 'center' : 'space-between'}>
        <FlexboxGrid.Item as={Col} lg={12} md={13} xs={24} className={styles['left-column']}>
          <div className={styles.tagline}>{t('common:Autonomous sourcing platform')}</div>
          <h1>{heading}</h1>
          <p className={styles.subtitle}>
            <SafeTranslate
              i18nKey="landing:Save resources otherwise spent managing orders and suppliers via emails, calls, and unorganized data"
              components={{
                span: <span className="highlight-text font-semibold" />,
              }}
            />
          </p>
          <Stack
            alignItems="stretch"
            spacing={15}
            wrap={isOnMobile}
            direction={isOnMobile ? 'column' : 'row'}
          >
            <Button
              appearance="primary"
              className={getButtonClassNames({ isTransparent: false })}
              onClick={() => (user ? pushT('/my-account') : openRegisterModal())}
              block
            >
              {user ? t('landing:My Droppe Account') : t('landing:Create a free account')}
            </Button>
            <Link href={urlT('/products')} shallow className={styles['cta-link']}>
              <Button
                appearance="ghost"
                className={`${getButtonClassNames({ isTransparent: true })} ${styles['start-sourcing-button']}`}
                block
              >
                {t('landing:Start sourcing_1')}
              </Button>
            </Link>
          </Stack>
        </FlexboxGrid.Item>
        {!isOnMobile && (
          <FlexboxGrid.Item
            as={Col}
            lg={12}
            md={11}
            className={styles['new-banner-container']}
          >
            <Img
              priority
              loader={imageLoader}
              src={imageLoader(`d24-hero-banner-${lang}.webp`)} // Uploaded directly to S3
              alt="Droppe Autonomous sourcing platform Visual Details"
              wMaxWidth="586px" // to match figma
              layout="fill"
            />
          </FlexboxGrid.Item>
        )}
      </FlexboxGrid>
    </div>
  )
}

export default HeroSection
